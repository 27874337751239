<template>
  <div class="material__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      方案用工管理
    </div>
    <div class="content">
      <div class style="margin-bottom: 24px">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="msgRecruitUse_text">需求发布时间</div>
              </a-col>
              <a-col :span="18">
                <a-date-picker
                  v-model="createTime"
                  value-format="YYYY-MM-DD"
                  placeholder="请输入需求发布时间"
                  style="width: 220px"
                />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="msgRecruitUse_text">方案标题</div>
              </a-col>
              <a-col :span="18">
                <a-input placeholder="请输入方案标题" style="width: 220px" allowClear v-model="title" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8" style="text-align: right" v-perms="'search'">
            <a-button type="" @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">查询</a-button>
          </a-col>
        </a-row>
      </div>
      <a-tabs type="card" v-model="status" @change="handleTabsChange">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.label">
          <div class="table__content">
            <p-table
              row-key="id"
              :columns="columns"
              :source-data="getTableData"
              :ref="`table${item.key}`"
              :scorll="{ y: 600 }"
              extraHeight="420"
            >
              <template slot="createTime" slot-scope="text">{{ formatTime(text) }}</template>
              <template slot="evaluate" slot-scope="evaluate">
                <a-rate :default-value="evaluate" slot="evaluate" />
              </template>
              <template slot="operation" slot-scope="text, record">
                <div class="operation_btn" @click="detail(record, '2')" v-if="status == 0 || status == 1" v-perms:[item.perms]="'details'">查看详情</div>

                <a-popconfirm
                  title="确认进行此操作?"
                  ok-text="是的"
                  cancel-text="取消"
                  @confirm="confirm('1', record)"
                  v-if="status == 0"
                  v-perms:[item.perms]="'jybgt'"
                >
                  <div class="operation_btn">进一步沟通</div>
                </a-popconfirm>

                <a-popconfirm
                  title="确认进行此操作?"
                  ok-text="是的"
                  cancel-text="取消"
                  @confirm="confirm('2', record)"
                  v-if="status == 1"
                  v-perms:[item.perms]="'remove'"
                >
                  <div class="operation_btn">移除</div>
                </a-popconfirm>

                <a-popconfirm
                  title="确认进行此操作?"
                  ok-text="是的"
                  cancel-text="取消"
                  @confirm="confirm('3', record)"
                  v-if="status == 1"
                  v-perms:[item.perms]="'confirm'"
                >
                  <div class="operation_btn">确认合作</div>
                </a-popconfirm>

                <a-popconfirm
                  title="确认进行此操作?"
                  ok-text="是的"
                  cancel-text="取消"
                  @confirm="confirm('4', record)"
                  v-if="status == 3"
                  v-perms:[item.perms]="'confirm-pass'"
                >
                  <div class="operation_btn">确认接受</div>
                </a-popconfirm>
              </template>
            </p-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal v-model="visible" title="详情" @ok="handleOk" width="50%" :footer="false">
      <a-row>
        <a-col :span="12">
          <div class="msgRecruitUse_title">姓名：{{ obj.realName }}</div>
        </a-col>
        <a-col :span="12">
          <div class="msgRecruitUse_title">性别：{{ obj.sex == 0 ? '男' : '女' }}</div>
        </a-col>
        <template v-if="obj.type === 1">
          <div class="detail-item">
            工种：
            <span>{{ obj.positionName }}</span>
          </div>
          <div class="detail-item">
            专业技能：
            <span>{{ obj.professionalSkill }}</span>
          </div>
          <div class="detail-item">
            基本技能：
            <span>{{ obj.baseSkill }}</span>
          </div>
          <div class="detail-item">
            项目经历：
            <span>{{ obj2[obj.experience] }}</span>
          </div>
          <div class="detail-item">
            技术职称证：
            <span>{{ obj3[obj.skillLevelCertificate] }}</span>
          </div>
        </template>
        <template v-else>
          <div class="detail-item">
            专业：
            <span>{{ obj.positionName }}</span>
          </div>
          <div class="detail-item">
            基本技能：
            <span>{{ obj.baseSkill }}</span>
          </div>
          <div class="detail-item">
            从业资格证：
            <span>{{ obj1[obj.qualificationCertificate] }}</span>
          </div>
          <div class="detail-item">
            工作经历：
            <span>{{ obj2[obj.experience] }}</span>
          </div>
          <div class="detail-item">
            职称等级：
            <span>{{ obj3[obj.skillLevelCertificate] }}</span>
          </div>
        </template>
        <a-col :span="12">
          <div class="msgRecruitUse_title">个人特长：{{ obj.specialty }}</div>
        </a-col>
        <a-col :span="12">
          <div class="msgRecruitUse_title">联系电话：{{ obj.phone }}</div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { employmentLearnDetail, employmentLearnModflyStuate, EnterpriseMaterialfindResponseUsers } from '@/api/msg'

const tabs = [
  {
    key: '0',
    label: '响应人员',
    perms: 'xyry'
  },
  {
    key: '1',
    label: '待沟通',
    perms: 'dgt'
  }
  // {
  //   key: '3',
  //   label: '待确认'
  // }
]

const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (text, row, index) => index + 1
  },
  {
    title: '方案标题',
    dataIndex: 'title'
  },
  {
    title: '姓名',
    dataIndex: 'realName'
  },

  {
    title: '年龄',
    dataIndex: 'age'
  },
  {
    title: '需求发布时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  // {
  //   title: '专业技能',
  //   dataIndex: 'professionalSkill'
  // },
  {
    title: '联系电话',
    dataIndex: 'phone'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
const obj1 = {
  1: '安全员',
  2: '建造师',
  0: '不限'
}

const obj2 = {
  1: '30万机组以下',
  2: '30-60万机组',
  3: '100万及以上机组',
  0: '不限'
}

const obj3 = {
  1: '初级',
  2: '中级',
  3: '高级',
  0: '不限',
  '-1': '特殊工种证书'
}
export default {
  name: 'Index',
  data () {
    return {
      value: 5,
      autoExpandParent: true,
      tableData: [],
      columns,
      visible: false,
      createTime: '',
      status: '0',
      title: '',
      obj: {},
      items: {},
      obj1,
      obj2,
      obj3
    }
  },

  computed: {
    tabs () {
      return tabs.filter(item => this.$getPerms(item.perms))
    },
    rowSelection () {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name
          }
        })
      }
    }
  },
  mounted () {
    // this.init()
  },
  methods: {
    confirm (val, row) {
      employmentLearnModflyStuate({
        demandId: row.demandId,
        userId: row.userId,
        status: val
      })
        .then((res) => {
          if (res.success) {
            this.$message.success('操作成功')
            this.$refs[`table${this.status}`][0].refresh()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleGetQuery () {
      const { title, createTime, status } = this
      return {
        createTime,
        status,
        title
      }
    },
    Evaluate () {
      this.isEvaluate = true
    },
    detail (record, val) {
      this.visible = true
      const params = {
        userId: record.userId
      }
      employmentLearnDetail(params).then((res) => {
        this.obj = res.data
      })
    },
    getTableData (params) {
      return EnterpriseMaterialfindResponseUsers({
        ...params,
        ...this.handleGetQuery()
      })
    },
    handleTabsChange (value) {
      this.status = value
      this.$nextTick(() => {
        this.$refs[`table${this.status}`][0].refresh()
      })
    },
    handleSearch () {
      this.$refs[`table${this.status}`][0].initPage()
    },
    handleReset () {
      this.createTime = undefined
      this.title = ''
    },
    handleOk () {
      this.visible = false
    },
    onChange () {}
  }
}
</script>

<style lang="less" scoped>
@import url('../../../common/style');
@import url('../../recruitUse/recruit/index');
@import url('../../recruitUse/evaluate/index');
@import url('./index');
.material__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }

}
.detail-item {
    width: 50%;
    font-size: 16px;
    margin-bottom: 10px;
    color: #222222;
    display: inline-block;
    span {
      color: rgba(0, 0, 0, 0.65);
    }
  }
</style>
